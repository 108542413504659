export const colors = {
  mainColor: `#00061A`,
  darkBlue: `#0C1225`,
  blue: `#00BAFF`,
  white: `#FFFFFF`,
  lightGray: `#868992`,
  lightGrayTwo: `#ABACB3`,
}

export const font = {
  uiText: `SF UI Text`,
  uiDisplay: `SF UI Display`,
}
