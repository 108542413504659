/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismic {
        allSite_settingss {
          edges {
            node {
              scripts_header
              scripts_footer
              title
            }
          }
        }
        allLayouts {
          edges {
            node {
              site_logo
              logo_footer
              copyright_text
              company_details
              headline
              footer_links {
                label
                link
                url {
                  ... on PRISMIC__ExternalLink {
                    target
                    _linkType
                    url
                  }
                }
              }
            }
          }
        }
        allNavigations {
          edges {
            node {
              navigation_links {
                item_label
                item_link
              }
            }
          }
        }
      }
    }
  `)

  const dataLayouts = data.prismic.allLayouts.edges[0].node
  const dataNavigation = data.prismic.allNavigations.edges[0].node

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        logo={dataLayouts.site_logo}
        navigationLinks={dataNavigation.navigation_links}
      />

      <div>
        <main>{children}</main>
      </div>

      <Footer
        logo={dataLayouts.logo_footer}
        copyright={dataLayouts.copyright_text}
        details={dataLayouts.company_details}
        headline={dataLayouts.headline}
        links={dataLayouts.footer_links}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
