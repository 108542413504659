import { Link } from "gatsby"
import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import { colors, font } from "./cssVariables"

const FooterStyled = styled.footer`
  background-color: ${colors.darkBlue};
  padding-top: 70px;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    padding-top: 105px;
    display: block;
  }

  @media (min-width: 1200px) {
    padding-bottom: 60px;
    padding-top: 120px;
  }

  .footer-content {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      margin-top: 35px;
    }

    &__details {
      margin-bottom: 32px;

      p {
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        color: #fff;
        margin-bottom: 5px;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    &__headline {
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${font.uiText};
        font-weight: normal;
        font-size: 22px;
        line-height: 29px;
        text-align: left;
        color: #fff;
        max-width: 292px;
        margin-top: 25px;
        margin-bottom: 38px;

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 32px;
          text-align: right;
          margin-top: 0;
          max-width: 350px;
        }

        @media (min-width: 1200px) {
          font-size: 28px;
          line-height: 38px;
          max-width: 400px;
        }
      }
    }
  }

  .footer-bottom {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }

    &__links {
      display: flex;
      margin: 0;
      list-style-type: none;

      li {
        margin-right: 28px;
        font-size: 12px;
        margin-bottom: 0;

        @media (min-width: 768px) {
          margin-right: 0;
          margin-left: 40px;
        }

        a {
          color: ${colors.lightGray};
          text-decoration: none;
        }
      }
    }

    &__copyright {
      color: ${colors.lightGray};
      font-size: 12px;
      line-height: 18px;
      opacity: 0.7;
    }
  }
`

const LogoFooter = styled.div`
  color: white;
  text-decoration: none;
  margin: 0;

  img {
    margin: 0;
    max-height: 28px;
  }
`

const Footer = ({ logo, headline, details, copyright, links }) => (
  <>
    <FooterStyled>
      <div className="container">
        <LogoFooter>
          <Link to="/">
            <img src={logo.url} alt={logo.alt} />
          </Link>
        </LogoFooter>
        <div className="footer-content">
          <div className="footer-content__headline">
            <RichText render={headline} />
          </div>
          <div className="footer-content__details">
            <RichText render={details} />
          </div>
        </div>
        <div className="footer-bottom">
          <ul className="footer-bottom__links">
            {links.map((item, i) => (
              <li key={i}>
                {item.link === null && item.url._linkType === "Link.web" ? (
                  <a href={item.url.url} target={item.url.target}>
                    {item.label}
                  </a>
                ) : (
                  <Link to={item.link}>{item.label}</Link>
                )}
              </li>
            ))}
          </ul>
          <div className="footer-bottom__copyright">{copyright}</div>
        </div>
      </div>
    </FooterStyled>
  </>
)

export default Footer
