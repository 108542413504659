import { createGlobalStyle } from "styled-components"

import SFUITextWoff from "./SFUIText-Regular.woff"
import SFUITextWoff2 from "./SFUIText-Regular.woff2"

import SFUITextBoldWoff from "./SFUIText-Bold.woff"
import SFUITextBoldWoff2 from "./SFUIText-Bold.woff2"

import SFUIDisplayBoldWoff from "./SFUIDisplay-Bold.woff"
import SFUIDisplayBoldWoff2 from "./SFUIDisplay-Bold.woff2"

export default createGlobalStyle`
    @font-face {
        font-family: 'SF UI Text';
        src: local('SF UI Text'), local('SFUIText'),
        url(${SFUITextWoff}) format('woff2'),
        url(${SFUITextWoff2}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SF UI Text';
        src: local('SF UI Text'), local('SF UI Text'),
        url(${SFUITextBoldWoff}) format('woff2'),
        url(${SFUITextBoldWoff2}) format('woff');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'SF UI Display';
        src: local('SF UI Display'), local('SFUIDisplay'),
        url(${SFUIDisplayBoldWoff}) format('woff2'),
        url(${SFUIDisplayBoldWoff2}) format('woff');
        font-weight: bold;
        font-style: normal;
    }
`
