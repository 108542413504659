import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { colors, font } from "./cssVariables"

const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  z-index: 9999;

  @media (min-width: 768px) {
    position: absolute;
  }
`

const Navbar = styled.div`
  padding: 24px 0;
  background-color: ${colors.mainColor};

  @media (min-width: 768px) {
    background-color: transparent;
  }

  @media (min-width: 1200px) {
    margin-top: 15px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const NavMenu = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }

  ul {
    list-style-type: none;
    display: flex;
    color: ${colors.white};
    margin-bottom: 0;

    li {
      margin-left: 45px;
      margin-bottom: 0;
      font-family: ${font.uiText};
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @media (min-width: 1200px) {
        font-size: 14px;
      }

      a {
        color: ${colors.white};
        text-decoration: none;
        &:hover {
          color: ${colors.white};
          text-decoration: none;
        }
      }
    }
  }
`

const NavMenuMobile = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  color: white;

  @media (min-width: 768px) {
    display: none;
  }

  ul {
    position: absolute;
    z-index: 999;
    list-style-type: none;
    color: white;
    margin-bottom: 0;
    text-align: right;
    right: 15px;
    bottom: 30px;

    li {
      margin-bottom: 0;
      font-size: 24px;
      &:not(:last-of-type) {
        margin-bottom: 34px;
      }

      a {
        color: ${colors.lightGrayTwo};
        text-decoration: none;
        &:hover {
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  .menu-toggle {
    transition: 0.25s ease-in-out;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: 20px;
    z-index: 999;
    cursor: pointer;

    .burguer {
      position: absolute;
      height: 100%;
      width: 100%;
      transition: 0.25s ease-in-out;
      right: 5px;

      span {
        transition: 0.25s ease-in-out;
        display: block;
        background: #fff;
        width: 25px;
        height: 1px;
        position: relative;
        top: 8px;
        left: 7px;
        margin: 5px 0;

        &:nth-child(1) {
          transition-delay: 0.5s;
          width: 29px;
        }
        &:nth-child(2) {
          transition-delay: 0.625s;
          width: 20px;
          left: 16px;
        }
        &:nth-child(3) {
          transition-delay: 0.75s;
          width: 25px;
          left: 11px;
        }
      }
    }
    .cross {
      transition: 0.25s ease-in-out;
      position: absolute;
      height: 100%;
      width: 100%;
      transform: rotate(45deg);
      top: 3px;

      span {
        transition: 0.25s ease-in-out;
        display: block;
        background: #fff;

        &:nth-child(1) {
          height: 0%;
          width: 1px;
          position: absolute;
          top: 10%;
          left: 18px;
          transition-delay: 0s;
        }
        &:nth-child(2) {
          width: 0%;
          height: 1px;
          position: absolute;
          left: 10%;
          top: 18px;
          transition-delay: 0.25s;
        }
      }
    }
  }

  .menu-toggle.open {
    .burguer {
      span {
        width: 0%;
        &:nth-child(1) {
          transition-delay: 0s;
        }
        &:nth-child(2) {
          transition-delay: 0.125s;
        }
        &:nth-child(3) {
          transition-delay: 0.25s;
        }
      }
    }
    .cross {
      span {
        &:nth-child(1) {
          height: 70%;
          transition-delay: 0.625s;
        }
      }
      span {
        &:nth-child(2) {
          width: 70%;
          transition-delay: 0.375s;
        }
      }
    }
  }

  .nav-menu-mobile {
    &__overlay {
      background: ${colors.darkBlue};
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;

      &.open {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
        overflow: hidden;
      }
    }
  }
`

const Logo = styled.div`
  color: white;
  text-decoration: none;
  margin: 0;
  display: inline-block;
  z-index: 999;
  position: relative;
  top: 5px;

  img {
    margin: 0;
    max-height: 22px;

    @media (min-width: 768px) {
      max-height: 26px;
    }

    @media (min-width: 1200px) {
      max-height: 28px;
    }
  }
`

const Header = ({ siteTitle, navigationLinks, logo }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    if (!menuOpen) {
      // document.body.style.position = "fixed"
      setMenuOpen(true)
    } else {
      // document.body.style.position = ""
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    menuOpen
      ? (document.body.style.position = "fixed")
      : (document.body.style.position = "")

    const enableBodyScroll = () => {
      document.body.style.position = ""
      setMenuOpen(false)
      console.log("resize")
    }

    window.addEventListener("resize", enableBodyScroll)
  })

  const MobileOverlay = ({ links }) => {
    return (
      <div
        className={
          menuOpen
            ? "nav-menu-mobile__overlay open"
            : "nav-menu-mobile__overlay"
        }
      >
        <ul>
          {links.map((item, i) => (
            <li key={i} onClick={() => toggleMobileMenu()}>
              <Link to={item.item_link}>{item.item_label}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <HeaderWrapper>
      <Navbar>
        <div className="container">
          <Logo>
            <Link to="/">
              <img src={logo.url} alt={siteTitle} />
            </Link>
          </Logo>

          <NavMenu>
            <ul>
              {navigationLinks.map((link, i) => (
                <li key={i}>
                  <Link to={link.item_link}>{link.item_label}</Link>
                </li>
              ))}
            </ul>
          </NavMenu>

          <NavMenuMobile className="nav-menu-mobile">
            <>
              <div
                className={menuOpen ? "menu-toggle open" : "menu-toggle"}
                onClick={() => toggleMobileMenu()}
              >
                <div className="burguer">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="cross">
                  <span></span>
                  <span></span>
                </div>
              </div>

              <MobileOverlay links={navigationLinks} />
            </>
          </NavMenuMobile>
        </div>
      </Navbar>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
